import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css'; // Add your custom CSS

const NavBar = () => {
  return (
    <nav className="nav-bar">
      <ul className="nav-items">
        {/* Home */}
        <li>
          <Link to="/" className="nav-link">
            <i className="fas fa-home"></i> {/* Font Awesome icon */}
            <span className="nav-text">Home</span>
          </Link>
        </li>

        {/* Mine */}
        <li>
          <Link to="/mine" className="nav-link">
            <i className="fas fa-gem"></i> {/* Mine icon */}
            <span className="nav-text">Mine</span>
          </Link>
        </li>

        {/* Partners */}
        <li>
          <Link to="/partner" className="nav-link">
            <i className="fas fa-users"></i> {/* Partners icon */}
            <span className="nav-text">Partners</span>
          </Link>
        </li>

        {/* Earn */}
        <li>
          <Link to="/earn" className="nav-link">
            <i className="fas fa-coins"></i> {/* Earn icon */}
            <span className="nav-text">Earn</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;