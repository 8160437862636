import React from 'react';
import './ReferralScreen.css'; // Assuming you're using a separate CSS file for styling

const ReferralScreen = () => {
  // Dummy referral IDs
  const referralIds = [
    '1234567890',
    '0987654321',
    '1122334455',
    '2233445566',
    '3344556677'
  ];

  return (
    <div className="referral-screen">
      <div className="inviter-section">
        <img src="path_to_image" alt="Inviter Logo" className="inviter-logo" />
        <p className="inviter-text">
          Your Inviter: <span className="inviter-name">alexO5555</span>
        </p>
        <div className="inviter-link">
          <span>https://t.me/Tetherminerapp</span>
          <button className="copy-button">Copy</button>
        </div>
      </div>

      <div className="referral-reward">
        <p>For each active referral, a reward of 1 USDT</p>
      </div>

      <div className="referral-list">
        <h3>Your referrals</h3>
        <ul>
          {referralIds.map((id, index) => (
            <li key={index} className="referral-item">
              {id}
            </li>
          ))}
        </ul>
      </div>

    </div>
  );
};

export default ReferralScreen;
