import React from 'react';
import { useLocation } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';  // Update import
import './PaymentScreen.css';

const PaymentScreen = ({ address = "0x71dFd92C0C876898D452C9c0542", onCopy }) => {
  const location = useLocation();
  const amount = location.state?.amount || 0; // Fallback to 0 if amount is undefined

  return (
    <div className="payment-screen">
      <div className="payment-card">
        <h2>Complete Your Payment</h2>

        <div className="qr-section">
          <QRCodeCanvas
            value={address}
            size={200}
            includeMargin={true}
            bgColor="#ffffff"
            fgColor="#000000"
          />
          <div className="amount-section">
            <h3>{amount.toFixed(6)} USDT</h3>
            <p>Payment Address: {address}</p>
            <button className="copy-button" onClick={onCopy}>
              COPY ADDRESS
            </button>
          </div>
        </div>

        <div className="order-details">
          <h3>Order Details</h3>
          <div className="details-row">
            <span>Deposit:</span>
            <span>{amount.toFixed(6)} USDT</span>
          </div>
          <div className="details-row">
            <span>Received:</span>
            <span>0.000000 USDT</span>
          </div>
          <div className="details-row">
            <span>Status:</span>
            <span>Pending</span>
          </div>
        </div>
      </div>

    </div>
  );
};

export default PaymentScreen;
