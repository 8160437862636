import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Mine.css'; // We'll modify the CSS to reflect a full-screen layout

const Mine = () => {
  const [amount, setAmount] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  // Handle input change
  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  // Handle the "Buy" button click
  const handleBuyClick = () => {
    const value = parseFloat(amount);
    if (value < 10) {
      setErrorMessage('Amount should be at least $10');
    } else {
      navigate('/payment', { state: { amount: value } }); // Pass the amount via state
    }
  };

  return (
    <div className="mine-full-screen">
      <div className="logo-section">
        <img src="/new2.png" alt="Logo" className="logo-img" />
      </div>

      <div className="mine-container">
        <div className="header">
          {/* <h2>Mobile Miner</h2> */}
          <p>Begin your journey into the world of mining with a basic package for beginners.</p>
        </div>

        <div className="details">
          <p>Upgrade period: <span>10 Days</span></p>
          <p>Cost: <span>T 8</span></p>
          <p>Profit: <span>T 16</span></p>

          <div className="benefits">
            <p><b>Benefit:</b> Earn 1% daily, and a maximum earning of 300%.</p>
          </div>

          <div className="input-section">
            <label htmlFor="amount">Enter amount (Min $10):</label>
            <input
              type="number"
              id="amount"
              placeholder="Enter amount in USD"
              value={amount}
              onChange={handleAmountChange}
            />
          </div>

          {errorMessage && <p className="error">{errorMessage}</p>}

          <div className="payment-system">
            <p>Payment system: <span>BEP20 USDT</span></p>
          </div>

          <button className="buy-btn" onClick={handleBuyClick}>BUY</button>
        </div>
      </div>
    </div>
  );
};

export default Mine;
