import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
import Mine from './Mine';
import PaymentScreen from './PaymentScreen';
import Partners from './ReferralScreen';
import Navbar from './NavBar'; // Import your Navbar component

const App = () => {
  return (
    <Router>
      <div className="app">
        {/* NavBar is outside Routes so it appears on every page */}
        <Navbar />

        {/* Define routes */}
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/mine" element={<Mine />} />
          <Route path="/payment" element={<PaymentScreen />} />
          <Route path="/partner" element={<Partners />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;