import React, { useContext } from 'react';
import './Dashboard.css'; // We'll create this file for styling later
import { TelegramContext } from './TelegramContext';
import { useSearchParams } from 'react-router-dom';

const Dashboard = () => {
  const [searchParams] = useSearchParams();
  const startParam = searchParams.get('start'); // Get the 'start' parameter

  console.log("Start parameter value:", startParam);
  console.log("Telegram user ID: ", window.Telegram?.WebApp.initDataUnsafe?.user?.id);
  return (
    <div className="dashboard">
      {/* Logo Section */}
      <div className="logo">
        <img src="/new2.png" alt="Logo" />
      </div>

      {/* Header Section */}
      <div className="header">
        <div className="user-info">
        <p>1116098165</p>
        </div>
        <div className="faq-icon">
          <p>FAQ</p>
          <div className="chat-icon">🔍</div>
        </div>
      </div>

      {/* Scrollable Content */}
      <div className="content">
        {/* Profit Section */}
        <div className="profit-section">
          <div className="profit-box">
            <p>Profit per click</p>
            <div className="profit-value">
              <img src="/usdt-icon.png" alt="USDT" />
              <p>+0.000200 $</p> {/* This will be replaced with USDT symbol */}
            </div>
          </div>
          <div className="profit-box">
            <p>Profit per hour</p>
            <div className="profit-value">
              <img src="/usdt-icon.png" alt="USDT" />
              <p>+0 $</p> {/* This will be replaced with USDT symbol */}
            </div>
          </div>
        </div>

        {/* Balance Section */}
        <div className="balance-section">
          <h1>$ 0.001400 </h1> {/* This will be replaced with USDT symbol */}
        </div>

        {/* Buttons Section */}
        <div className="buttons-section">
          <button className="withdraw-btn">WITHDRAW</button>
          <button className="mine-btn">MINE</button>
        </div>

        {/* USDT Logo */}
        <div className="usdt-logo">
          <img src="/usdt-logo.png" alt="USDT" />
        </div>

        {/* Footer with energy */}
        <div className="energy-section">
          <p>1000 / 1000 ⚡</p>
        </div>
      </div>

      {/* Fixed Navigation Bar */}
     
    </div>
  );
};

export default Dashboard;